import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonDialogData } from './typeing';

@Component({
  selector: 'app-common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss'],
})
export class CommonDialogComponent implements OnInit {
  public title = '';
  public content = '';
  public okText = '';
  public cancelText = '';

  constructor(
    public dialogRef: MatDialogRef<CommonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommonDialogData
  ) { }

  ngOnInit(): void {
    switch (this.data.code) {
      case 110:
        this.content = `格式錯誤，請上傳${this.data.parameters?.['type']}格式`;
        this.okText = '關閉';
        break;
      case 109:
        this.content = '無對應的使用者';
        this.okText = '關閉';
        break;
      case 108:
        this.content = `格式錯誤，檔案大小超過 ${this.data.parameters?.['limit']} MB`;
        this.okText = '關閉';
        break;
      case 107:
        this.content = '資料不存在，請與業務人員確認。';
        this.okText = '返回';
        break;
      case 106:
        this.content = '請先選擇對話主題';
        this.okText = '返回';
        break;
      case 105:
        this.content = '資料不存在，請與業務人員確認。';
        this.okText = '返回LINE';
        break;
      case 104:
        this.content = '您目前無權限使用此頁面，若有疑問，請聯繫相關部門人員。';
        this.okText = '關閉';
        break;
      case 103:
        this.content = '您已完成註冊！';
        this.okText = '返回LINE';
        break;
      case 102:
        this.content = '您的資料確認中';
        this.okText = '返回LINE';
        break;
      case 101:
        this.content = '你已經完成驗證';
        this.okText = '返回LINE';
        break;
      case 100:
        this.content = '您已完成註冊！';
        this.okText = '關閉';
        break;
      case 1:
        this.content = '恭喜您已完成註冊！';
        this.okText = '返回LINE';
        break;
      case 2:
        this.content = '您已完成註冊！';
        this.okText = '關閉';
        break;
      case 3:
        this.content = '您目前無權限使用此頁面，若有疑問，請聯繫相關部門人員。';
        this.okText = '關閉';
        break;
      case 4:
        this.content = '您尚未加入LINE官方帳號。';
        this.okText = '立即加入';
        break;
      case 5:
        this.content = '請輸入 09 開頭共十碼的手機格式。';
        this.okText = '知道了';
        break;
      case 6:
        this.content = '無符合資料，請聯繫相關部門人員。';
        this.okText = '知道了';
        break;
      case 7:
        this.content = '驗證碼錯誤。';
        this.okText = '關閉';
        break;
      case 8:
        this.content = '驗證碼過期。';
        this.okText = '關閉';
        break;
      case 9:
        this.content = '操作太頻繁，請稍後再試。';
        this.okText = '關閉';
        break;
      case 10:
        this.content = '驗證失敗，請再試一次。';
        this.okText = '關閉';
        break;
      case 11:
        this.title = '此手機號碼已被註冊';
        this.content = '請確認手機號碼是否正確。';
        this.okText = '查看HCP清單';
        this.cancelText = '關閉';
        break;
      case 20:
        this.content = '您的身份無法使用HCP對話功能';
        this.okText = '關閉';
        break;
      case 21:
        this.content = '為了您的帳戶安全，系統將您的帳號進行登出，請您重新登錄';
        this.okText = '重新登入';
        this.cancelText = '關閉';
        break;
      case 22:
        this.content = '您的身份無法使用此網站功能';
        this.okText = '關閉';
        break;
    }
  }

  clickOk() {
    this.dialogRef.close(true);
  }

  clickCancel() {
    this.dialogRef.close(false);
  }
}
