import { Inject, Injectable } from '@angular/core';
import { FIREBASE_REFERENCES } from '../shared/methods/firebase.module';
import { SharedService } from '../shared/shared.service';
import { environment } from 'src/environments/environment';
import { AiiiError } from '@aiii/aiii-types';
import { collection, doc, Firestore, getDoc, getDocs, query, where } from '@angular/fire/firestore';
import { CheckHcpExistRequest } from '@aiii/aiii-types/product/med/api/hcp/check-hcp-exist-request';
import { RegisterHcpRequest } from '@aiii/aiii-types/product/med/api/hcp/register-hcp-request';
import { limit } from 'firebase/firestore';
import { HcpComprehensiveInfoView } from '@aiii/aiii-types/product/med/big-query-schema/hcp/hcp-comprehensive-info-view';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { GET_SITE } from '../shared/providers/get-site.provider';
import { HCP } from '@aiii/aiii-types/product/med/role/hcp';

@Injectable({
  providedIn: 'root',
})
export class HcpService {
  constructor(
    @Inject(FIREBASE_REFERENCES.TAIWAN_FIRESTORE)
    private readonly twFirestore: Firestore,
    private readonly firestore: Firestore,
    private sharedService: SharedService,
    private http: HttpClient,
    @Inject(GET_SITE) private site: string
  ) {}

  async getSiteLiffLogoUrl(site: string): Promise<string> {
    const siteDocRef = doc(this.firestore, 'sites', site);
    const docSnapshot = await getDoc(siteDocRef);

    if (docSnapshot.exists()) {
      return (docSnapshot.get('medOption.liffConfig.logoUrl') as string) ?? '';
    } else {
      return '';
    }
  }

  async getMedPrivacyPolicy(site: string): Promise<any> {
    let medPrivacyPolicy: any | undefined = undefined;
    const medPrivacyPolicyColRef = collection(this.twFirestore, `sites/${site}/medPrivacyPolicies`);
    // query isPublish = true , 只取 limit 1
    const medPrivacyPolicyQuery = query(medPrivacyPolicyColRef, where('isPublish', '==', true), limit(1));
    const querySnapshot = await getDocs(medPrivacyPolicyQuery);

    console.log('querySnapshot', querySnapshot.empty);
    if (!querySnapshot.empty) {
      medPrivacyPolicy = querySnapshot.docs[0].data() as any;
    }

    return medPrivacyPolicy;
  }

  async getMedicalInstitution(site: string, medicalInstitutionKey: string) {
    let medicalInstitution: any | undefined = undefined;
    const medicalInstitutionDef = doc(this.twFirestore, `/sites/${site}/medicalInstitution/${medicalInstitutionKey}`);
    const medicalInstitutionDoc = await getDoc(medicalInstitutionDef);

    if (medicalInstitutionDoc.exists()) {
      medicalInstitution = medicalInstitutionDoc.data() as any;
    }

    return medicalInstitution;
  }

  async getMedicalInstitutionList(site: string) {
    const medicalInstitutionColRef = collection(this.twFirestore, `/sites/${site}/medicalInstitution`);
    const medicalInstitutionSnapshot = await getDocs(medicalInstitutionColRef);
    return medicalInstitutionSnapshot.docs.map((doc) => doc.data() as any);
  }

  async getHcp(site: string, hcpKey: string) {
    const rsp = await this.sharedService
      .ajaxJsonPost(`${environment.cloudrun.med}/hcp/get-hcp`, {
        site: site,
        hcpKey: hcpKey,
      })
      .catch((err: any) => {
        return undefined;
      });

    return rsp;
  }

  async getHcpByLINEUserId(site: string, LINEUserId: string): Promise<HCP | undefined> {
    const roleHcpRef = collection(this.twFirestore, `/sites/${site}/hcp`);
    const roleHcpQuery = query(roleHcpRef, where('LINEUserId', '==', LINEUserId));
    const roleHcpSnapshot = await getDocs(roleHcpQuery);

    let hcp: HCP | undefined = undefined;
    if (!roleHcpSnapshot.empty) {
      hcp = roleHcpSnapshot.docs[0].data() as HCP;
    }

    return hcp;
  }

  async getMedicalInstitutionListByQuery(site: string, name: string, county: string, district: string): Promise<any> {
    const getMedicalInstitutionListByNameRequest = {
      site: site,
      name: name,
      county: county,
      district: district,
    };

    const rep = await this.sharedService.ajaxJsonPost(
      `${environment.cloudrun.med}/medical-institution/get-site-medical-institution-by-query`,
      getMedicalInstitutionListByNameRequest,
      environment.cloudrun.medAPIKey
    );

    return rep;
  }

  async getSiteDepartment(site: string): Promise<any> {
    const getSiteDepartment = {
      site: site,
    };

    const rep = await this.sharedService.ajaxJsonPost(
      `${environment.cloudrun.med}/medical-institution/get-site-department`,
      getSiteDepartment,
      environment.cloudrun.medAPIKey
    );

    return rep;
  }

  async checkHcpExist(checkHcpExistRequest: CheckHcpExistRequest): Promise<any> {
    const rsp = await this.sharedService.ajaxJsonPost(
      `${environment.cloudrun.med}/hcp/check-hcp-exist`,
      checkHcpExistRequest
    );

    console.log('checkHcpExist rsp', rsp);

    return rsp;
  }

  async getHcpListByUserIdList(userIdList: string[]): Promise<any> {
    const hcpListByUserIdListRequest = {
      userIdList: userIdList,
    };

    const rep = await this.sharedService.ajaxJsonPost(
      `${environment.cloudrun.med}/hcp/list-by-user-id-list`,
      hcpListByUserIdListRequest
    );

    return rep.body;
  }

  async checkIsHcpExistByPhone(phone: string): Promise<boolean> {
    const roleHcpRef = collection(this.twFirestore, '/sites/nnhcp/roleHcp');
    const roleHcpQuery = query(roleHcpRef, where('phone', '==', phone));
    const roleHcpSnapshot = await getDocs(roleHcpQuery);

    return !roleHcpSnapshot.empty;
  }

  async registerHcp(registerHcpRequest: RegisterHcpRequest): Promise<any> {
    const rsp = await this.sharedService.ajaxJsonPost(
      `${environment.cloudrun.med}/hcp/register-hcp`,
      registerHcpRequest
    );

    return rsp;
  }

  async verifyActivityExistHcp(verifyActivityExistHcpRequest: any) {
    const rsp = await this.sharedService.ajaxJsonPost(
      `${environment.cloudrun.med}/hcp/verify-activity-exist-hcp`,
      verifyActivityExistHcpRequest
    );
    return rsp;
  }

  updatePersonalInfoConsentForm(updatePersonalInfoConsentFormRequest: {
    hcpKey: any;
    diabetesRelatedField: boolean;
    weightLossRelatedField: boolean;
    hemophiliaRelatedField: boolean;
    growthHormoneRelatedField: boolean;
  }) {
    const rsp = this.sharedService.ajaxJsonPost(
      `${environment.cloudrun.med}/hcp/update-hcp-personal-info-consent-form`,
      updatePersonalInfoConsentFormRequest
    );

    return rsp;
  }

  getSiteHospitalCountyAndDistrict(site: string) {
    const rsp = this.sharedService.ajaxJsonPost(
      `${environment.cloudrun.med}/medical-institution/get-site-hospital-county-and-district`,
      {
        site: site,
      },
      environment.cloudrun.medAPIKey
    );

    return rsp;
  }

  createHcp(createHcpRequest: any): Promise<any> {
    const rsp = this.sharedService.ajaxJsonPost(`${environment.cloudrun.med}/hcp/create-hcp`, createHcpRequest);

    return rsp;
  }

  async getJobList(site: string) {
    const jobColRef = collection(this.twFirestore, `/sites/${site}/job`);
    const jobQuery = query(jobColRef, where('role', '==', 'hcp'));
    const jobSnapshot = await getDocs(jobQuery);

    let jobList: any[] = [];
    if (!jobSnapshot.empty) {
      jobList = jobSnapshot.docs.map((doc) => doc.data() as any);
    }

    return jobList;
  }

  async getSiteMedOption(site: string) {
    const siteDocRef = doc(this.firestore, 'sites', site);
    const docSnapshot = await getDoc(siteDocRef);

    return docSnapshot.get('medOption');
  }

  /**
   * TODO:好像不需要
   * 取得站台內已驗證 HCP 列表
   * page 頁數
   * limit 每頁筆數
   * filter 輸入框搜尋字串
   * @returns
   */
  async getSiteVerifyHcpList(option?: {
    page?: number;
    limit?: number;
    filter?: string;
  }): Promise<HcpComprehensiveInfoView[]> {
    const result = await lastValueFrom(
      this.http.post<HcpComprehensiveInfoView[]>(`${environment.cloudrun.med}/hcp/list-backend`, {
        ...option,
        site: this.site,
        sort: 'DESC',
        queryOption: { verificationStatus: ['已驗證'] },
      })
    );
    return result;
  }
}
