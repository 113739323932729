import { AiiiError } from '@aiii/aiii-types';
import { environment } from 'src/environments/environment';
import { HCPList } from './typings';

/**
 * HCP 列表參數
 * @interface HcpListParams
 */
export interface HcpListParams {
  /** 站台 */
  site: string;
  /** 業務 key */
  medSalesKey: string;
  /** 姓名搜尋 */
  nameSearch: string;
  /** 院所搜尋 */
  institutionSearch: string;
  /** 產品搜尋 */
  productSearch: string;
  /** 分類搜尋 */
  classSearch: string;
}

/**
 * 取得 HCP 列表
 * @param {HcpListParams} params - HCP 列表參數
 * @param {string} params.site - 站台
 * @param {string} params.medSalesKey - 業務 key
 * @param {string} params.nameSearch - 姓名搜尋
 * @param {string} params.institutionSearch - 院所搜尋
 * @param {string} params.productSearch - 產品搜尋
 * @param {string} params.classSearch - 分類搜尋
 * @returns {Promise<HCPList>} - HCP 列表的 Promise
 */

export const getHcpListFromMedSalesGsk = async ({
  site,
  medSalesKey,
  nameSearch,
  institutionSearch,
  productSearch,
  classSearch,
}: HcpListParams): Promise<HCPList> => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-api-key', environment.cloudrun.medAPIKey);

  const raw = JSON.stringify({
    site,
    medSalesKey,
    nameSearch,
    institutionSearch,
    productSearch,
    classSearch,
  });

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const result = await fetch(`${environment.cloudrun.gskApi}/hcp/list-for-med-sales`, requestOptions);

  if (!result.ok) {
    throw new AiiiError(400, '取得HCP列表失敗');
  }
  return result.json() as Promise<HCPList>;
};
