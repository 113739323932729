import { Injectable } from '@angular/core';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Firestore } from '@angular/fire/firestore';
import { firstValueFrom } from 'rxjs';

import { environment } from 'src/environments/environment';

type MedicalInstitution = {
  _key: string;
  name: string;
  aliasNameList: string[];
  county: string;
  district: string;
};

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public progress = 0;

  public updateRichMenuOnCall = httpsCallable(getFunctions(), 'updateRichMenuOnCall');

  constructor(public storage: Firestore, public http: HttpClient, private router: Router) {}

  public async sleep(microsecond: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, microsecond);
    });
  }

  //  * 修改指定 user 的 RichMenu
  //  * @param adminToken firebase 管理者 Token
  //  * @param site 站台名稱
  //  * @param userId userId
  //  * @param richMenuId  richMenuId 若為空值則重置 richMenu

  async updateRichMenu(adminToken: string, site: string, userId: string, richMenuId: string = '') {
    try {
      const result = await this.updateRichMenuOnCall({ idToken: adminToken, site, userId, richMenuId });
      return result.data;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  ajaxFormPost<T = any>(url: string, data: any, apikey?: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    });

    if (apikey) {
      headers = headers.append('x-api-key', apikey);
    }

    let urlSearchParams = new URLSearchParams();
    for (let name in data) {
      urlSearchParams.set(name, data[name]);
    }
    return this.http.post<T>(url, urlSearchParams.toString(), { observe: 'body', headers: headers });
  }

  /**
   * ajax方法
   * @param url
   * @param data
   */
  ajaxJsonPost<T = any>(url: string, data: any, apikey?: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
    });

    if (apikey) {
      headers = headers.append('x-api-key', apikey);
    }

    return firstValueFrom(this.http.post<T>(url, data, { observe: 'body', headers: headers }));
  }

  /**
   * ajax方法
   * @param url
   * @param data
   */
  httpClientPostBlob(url: string, formData: FormData, apikey?: string) {
    let headers = new HttpHeaders();
    if (apikey) {
      headers = headers.append('x-api-key', apikey);
    }

    return firstValueFrom(this.http.post<Blob>(url, formData, { headers: headers, responseType: 'json' }));
  }

  /**
   * ajax方法
   * @param url
   * @param data
   */
  async Get<T = any>(url: string, data?: any) {
    const requestUrl = new URL(url);
    for (let name in data) {
      requestUrl.searchParams.set(name, data[name]);
    }
    const result = this.http.get<T>(requestUrl.href);
    return result;
  }

  /**
   * 頁面導航
   * @param path 路由
   * @param queryParams 查詢參數
   * @memberof SalesService
   * @example
   * navigate('/hcp/hcp-register', { site: 'nnhcp' })
   */
  navigate(path: string = '', queryParams: { site: string }): void {
    this.router.navigate([`${path}`], {
      queryParams: queryParams,
    });
  }

  /**
   * 取得醫療機構列表
   * @param site 站台
   * @param name 名稱
   * @param county 縣市
   * @param district 鄉鎮市區
   * @returns 醫療機構列表
   */
  async getMedicalInstitutionListByQuery(
    site: string,
    name: string,
    county: string,
    district: string
  ): Promise<MedicalInstitution[]> {
    const getMedicalInstitutionListByNameRequest = {
      site: site,
      name: name,
      county: county,
      district: district,
    };

    const rep = await this.ajaxJsonPost(
      `${environment.cloudrun.med}/medical-institution/get-site-medical-institution-by-query`,
      getMedicalInstitutionListByNameRequest,
      environment.cloudrun.medAPIKey
    );

    return rep;
  }
}
