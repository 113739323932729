import { InjectionToken, NgModule, NgZone, PLATFORM_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ENV, ENV_CONFIG } from 'src/app/shared/interface/firebase-env';
import { getFirestore } from '@angular/fire/firestore';
import { initializeApp } from '@angular/fire/app';
import { environment } from 'src/environments/environment';
import { getAuth } from 'firebase/auth';

export function firestoreFactory(projectId: string) {
  const url = new URL(window.location.href);
  // 使用 URLSearchParams 取得參數
  const params = new URLSearchParams(url.search);
  const site: string = 'gskline';

  projectId = environment.useOtherFirebaseProject.sites.includes(site) ? site : projectId;

  const env = environment as any as ENV;
  const firebaseConfig = env.firebaseConfig[projectId];
  // 初始化 Firebase 应用
  const app = initializeApp(firebaseConfig, firebaseConfig.projectId);

  return getFirestore(app);
}

export function fireAuthFactory(projectId: string) {
  const url = new URL(window.location.href);
  // 使用 URLSearchParams 取得參數
  const params = new URLSearchParams(url.search);
  const site: string = 'gskline';

  projectId = environment.useOtherFirebaseProject.sites.includes(site) ? site : projectId;

  const env = environment as any as ENV;
  const firebaseConfig = env.firebaseConfig[projectId];
  const app = initializeApp(firebaseConfig, firebaseConfig.projectId);

  return getAuth(app);
}


export const FIREBASE_PROJECT_US = new InjectionToken<ENV>('firebase project injector for us');
export const FIREBASE_PROJECT_TAIWAN = new InjectionToken<ENV>('firebase project injector for taiwan');

export const FIREBASE_REFERENCES = {
  US_FIRESTORE: 'project-us-firestore',
  US_FIRESTORAGE: 'project-us-firestorage',
  US_FIREAUTH: 'project-us-fireauth',
  US_FIREFUNCTIONS: 'project-us-functions',
  TAIWAN_FIRESTORE: 'project-taiwan-firestore',
  TAIWAN_FIRESTORAGE: 'project-taiwan-firestorage',
  TAIWAN_FIREAUTH: 'project-taiwan-fireauth',
  TAIWAN_FIREFUNCTIONS: 'project-taiwan-functions',
};
