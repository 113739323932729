<div class="selectMedicalInstitution">
  <form [formGroup]="medicalInstitutionFormGroup">
    <div class="infoFieldBoxDiv mb-3">
      <button
        *ngIf="medicalInstitutionIndex >= 1 && !medicalInstitutionFormGroup.value.boundTerritoryKey"
        mat-icon-button
        class="btnDeleteInfoFieldBoxDiv"
        (click)="removeMedicalInstitution()"
      >
        <mat-icon>cancel</mat-icon>
      </button>
      <mat-form-field appearance="outline">
        <mat-label class="nnhcpLabel">請輸入院所名</mat-label>
        <input
          type="text"
          matInput
          [matAutocomplete]="auto"
          #medicalInstitutionNameInput
          formControlName="medicalInstitutionKey"
          (input)="onInput($event)"
          (blur)="onInputBlur()"
          (compositionstart)="compositionStart()"
          (compositionend)="compositionEnd($event)"
          matAutocompletePosition="below"
          [disabled]="!!medicalInstitutionFormGroup.value.boundTerritoryKey"
        />
        <mat-spinner *ngIf="isQueryAutocomplete" matSuffix [diameter]="18" style="margin-right: 10px"></mat-spinner>
        <mat-autocomplete
          autoActiveFirstOption
          #auto="matAutocomplete"
          (optionSelected)="optionSelectedMedicalInstitution($event)"
          [displayWith]="displayMedicalInstitutionName"
        >
          <mat-option
            *ngFor="let medicalInstitution of autoCompleteMedicalInstitutionList"
            [value]="medicalInstitution._key"
          >
            {{ medicalInstitution.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div class="cityDiv">
        <mat-form-field appearance="outline">
          <mat-label class="nnhcpLabel">選擇院所縣市</mat-label>
          <mat-select
            [value]="selectedCountry"
            (valueChange)="countryChange($event)"
            [disabled]="!!medicalInstitutionFormGroup.value.boundTerritoryKey"
          >
            <mat-option value="">請選擇</mat-option>
            <mat-option *ngFor="let county of countyList || []" [value]="county"> {{ county.countyName }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" >
          <mat-label class="nnhcpLabel">選擇院所區域</mat-label>
          <mat-select
            [value]="selectedDistrict"
            (valueChange)="districtChange($event)"
            [disabled]="!!medicalInstitutionFormGroup.value.boundTerritoryKey"
          >
            <mat-option value="">請選擇</mat-option>
            <mat-option *ngFor="let district of selectedCountry?.districtList || []" [value]="district">
              {{ district.districtName }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline">
        <mat-label class="nnhcpLabel">科別</mat-label>
        <input
          type="text"
          matInput
          #departmentInput
          [matAutocomplete]="departmentAuto"
          formControlName="departmentKey"
          (input)="onDepartmentInput($event)"
          (blur)="onDepartmentInputBlur()"
          matAutocompletePosition="below"
          [disabled]="!!medicalInstitutionFormGroup.value.boundTerritoryKey"
        />
        <mat-autocomplete
          autoActiveFirstOption
          #departmentAuto="matAutocomplete"
          [displayWith]="displayDepartmentName"
          (optionSelected)="optionSelectedDepartment($event)"
        >
          <mat-option *ngFor="let department of autoCompleteDepartList" [value]="department._key">
            {{ department.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label class="nnhcpLabel">職稱</mat-label>
        <mat-select formControlName="jobKey">
          <mat-option *ngFor="let job of jobList" [value]="job._key">{{ job.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</div>

