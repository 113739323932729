import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { MedSalesListService } from 'src/app/med-chat/med-sales-list/med-sales-list.service';

export const medChatAuthGuard: CanActivateFn = async(route, state) => {
  const medSalesListService = inject(MedSalesListService);

  const email = prompt('請輸入 email');
  try {
    await medSalesListService.getSalesForTest(email ?? ''); // 'noemin@aiii.ai' // email ?? ''
    return true;
  } catch ( error: any ) {
    console.log('登入失敗', error.massage);
    return false;
  }

};
