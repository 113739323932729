import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private _theme = new BehaviorSubject<any>(null);
  theme$ = this._theme.asObservable();

  constructor() { }

  setTheme(theme: any) {
    this._theme.next(theme);
    this.updateCssVariables(theme);
  }
	
  private updateCssVariables(theme: any) {
    Object.keys(theme).forEach(key => {
      document.documentElement.style.setProperty(`--${key}`, theme[key]);
      // 加上元件停用的色碼，元件顏色20%透明度
      if (key === 'component-color') {
        const componentDisabledColor = `${theme[key]}33`
        document.documentElement.style.setProperty(`--component-disabled-color`, componentDisabledColor);
      }
    });
  }
}