<div class="pageWrapper">
  <div class="logoImgDiv">
    <img [src]="medSalesMainService.logo" class="logoImg" />
  </div>
  <div class="title">HCP 邀請 QR code</div>
  <div class="inviterInfo">
    您將邀請 {{ data.hcpName }} 掃描加入
  </div>

  <div class="divider"></div>

  <div class="qrcodeDiv">
    <img [src]="inviteHcpQrCodeUrl" class="qrcodeImg" />
  </div>

  <button
    [cdkCopyToClipboard]="data.hcpName + ' 您好，誠摯邀請您加入' + data.siteName + '官方LINE！立刻點擊以下連結加入 ' + hcpRegisterUrl"
    mat-stroked-button
  >
    <mat-icon>content_copy</mat-icon>複製網址
  </button>

  <button mat-flat-button mat-dialog-close>關閉</button>
</div>
