import { AiiiError } from '@aiii/aiii-types';
import { Department } from '@aiii/aiii-types/product/med/big-query-schema/department/department';
import { environment } from 'src/environments/environment';

/**
 * 取得科別清單
 * @param {string} site 站台
 * @return {Promise<any>}
 */
export const getMedicalDepartmentList = async (site: string): Promise<Department[]> => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-api-key', environment.cloudrun.medAPIKey);

  const raw = JSON.stringify({
    site,
  });

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const result = await fetch(`${environment.cloudrun.med}/medical-institution/get-site-department`, requestOptions);
  if (!result.ok) {
    throw new AiiiError(400, '取得科別清單失敗');
  }
  
  return result.json();
};
