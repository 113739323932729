import { AfterViewInit, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { VerifyPhoneService } from './verify-phone.service';
import { VerifyPhoneResult } from './typeing';
import * as firebase from 'firebase/app';
import { FIREBASE_REFERENCES } from '../../methods/firebase.module';
import {
  Auth,
  ConfirmationResult,
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithCredential,
  signInWithPhoneNumber,
} from '@angular/fire/auth';

@Component({
  selector: 'app-verify-phone',
  templateUrl: './verify-phone.component.html',
  styleUrls: ['./verify-phone.component.scss'],
  providers: [VerifyPhoneService],
})
export class VerifyPhoneComponent implements AfterViewInit {
  @Input() phone = '';
  @Input() optResendTime = 30;
  @Input() logoUrl = '';
  @Output() verifyPhoneResult: EventEmitter<VerifyPhoneResult> = new EventEmitter();

  public isProgress = true;
  public timer = 30;
  public errorMessage = '';
  public verificationCode = '';
  private verificationId = '';
  private recaptchaVerifier!: RecaptchaVerifier;

  constructor(@Inject(FIREBASE_REFERENCES.US_FIREAUTH) private readonly usFireAuth: Auth) {}

  async ngAfterViewInit() {
    this.recaptchaVerifier = new RecaptchaVerifier(this.usFireAuth, 'sign-in-button', { size: 'invisible' });

    setTimeout(() => {
      this.sendPhoneCode();
    }, 0);
  }

  checkForm() {
    return !!this.verificationCode;
  }

  otpTimer(time: number) {
    this.timer = time;
    const interval = setInterval(() => {
      this.timer--;
      if (this.timer < 0) clearInterval(interval);
    }, 1000);
  }

  checkVerificationCodeIsPhone() {
    return this.verificationCode.match(/^09\d{2}\s?\d{3}\s?\d{3}$/);
  }

  async sendPhoneCode() {
    this.isProgress = true;
    try {
      this.otpTimer(this.optResendTime);
      const inputPhone = `+886${this.phone.substring(1)}`;
      const confirmationResult = await signInWithPhoneNumber(this.usFireAuth, inputPhone, this.recaptchaVerifier);
      this.verificationId = confirmationResult.verificationId;
    } catch (error: any) {
      let errorCode = 10;
      if (error.code === 'auth/too-many-requests') {
        errorCode = 9;
      }
      this.verifyPhoneResult.emit({
        result: 'fail',
        errorCode: errorCode,
        phoneVerificationCode: this.verificationCode,
        resultMessage: this.errorMessage,
      });
    } finally {
      this.isProgress = false;
    }
  }

  async _btnOtpVerify() {
    this.isProgress = true;
    // 等待個十秒
    this.errorMessage = '';
    try {
      // 後門通過規則
      if (this.verificationCode === this.phone) {
        this.verifyPhoneResult.emit({
          result: 'success',
          phoneVerificationCode: this.verificationCode,
          resultMessage: '',
        });
      } else {
        const credentials = PhoneAuthProvider.credential(this.verificationId, this.verificationCode);
        await signInWithCredential(this.usFireAuth, credentials);
        this.verifyPhoneResult.emit({
          result: 'success',
          phoneVerificationCode: this.verificationCode,
          resultMessage: '',
        });
      }
    } catch (error: any) {
      console.error(error);

      let errorCode = 10;

      if (error.code === 'auth/invalid-verification-code') {
        errorCode = 7;
      } else if (error.code === 'auth/code-expired') {
        errorCode = 8;
      }

      this.verifyPhoneResult.emit({
        result: 'fail',
        errorCode: errorCode,
        phoneVerificationCode: this.verificationCode,
        resultMessage: this.errorMessage,
      });
    } finally {
      this.isProgress = false;
    }
  }

  _btnPrev() {
    this.verifyPhoneResult.emit({
      result: 'cancel',
      phoneVerificationCode: this.verificationCode,
      resultMessage: '',
    });
  }

  // onInputChange(event: Event): void {

  //   setTimeout(() => {
  //     const inputEvent = event as InputEvent;
  //     const inputElement = event.target as HTMLInputElement;
  //     // 根據 inputType 區分手動輸入還是自動填充
  //     if (inputEvent.inputType === 'insertReplacementText' || inputEvent.inputType === 'insertCompositionText' || inputEvent.inputType === 'insertFromPaste') {
  //       // 從 inputEvent.data 取得輸入的值
  //       const otpValue = inputElement.value;
  //       inputElement.value = otpValue.slice(0, 6);
  //     }
  //   }, 50); // 50ms 延遲，確保自動填充完成
 
  // }
}
