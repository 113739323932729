import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { SharedMaterialModule } from './shared/shared-material.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { fireAuthFactory, FIREBASE_REFERENCES, firestoreFactory } from './shared/methods/firebase.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    SharedMaterialModule,
    FormsModule,
    SharedModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedMaterialModule,
    AppRoutingModule,
    BrowserAnimationsModule,
  ],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    { provide: FIREBASE_REFERENCES.US_FIRESTORE, useFactory: () => firestoreFactory('us') },
    { provide: FIREBASE_REFERENCES.TAIWAN_FIRESTORE, useFactory: () => firestoreFactory('taiwan') },
    { provide: FIREBASE_REFERENCES.US_FIREAUTH, useFactory: () => fireAuthFactory('us') },
    { provide: FIREBASE_REFERENCES.TAIWAN_FIREAUTH, useFactory: () => fireAuthFactory('taiwan') },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
