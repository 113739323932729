import { Injectable, Inject } from '@angular/core';
import { doc, Firestore, getDoc, collection, query, where, getDocs } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { SiteConfig } from '@aiii/aiii-types';
import { Department } from '@aiii/aiii-types/product/med/big-query-schema/department/department';

import { getHcpListFromMedSales } from 'src/app/shared/methods/api/get-hcp-list-from-med-sales';
import { GET_SITE } from 'src/app/shared/providers/get-site.provider';
import { LiffService } from 'src/app/shared/liff.service';
import { getMedicalDepartmentList } from 'src/app/shared/methods/api/get-medical-department';
import { HCP } from './typing';
import { FIREBASE_REFERENCES } from 'src/app/shared/methods/firebase.module';
import { MedSales } from '@aiii/aiii-types/product/med/firestore-schema/med-sales/med-sales';

@Injectable({
  providedIn: 'root',
})
export class MedSalesMainService {
  public siteConfig!: SiteConfig;
  public logo: string = '';
  public medSalesKey: string = '';
  public medSalesViewTerritory: string[] = [];
  public assignedHcpList: HCP[] = [];
  public unassignedHcpList: HCP[] = [];
  private sales: MedSales | undefined;
  public assignedListPage = 0;
  public assignedListTotalPage = 0;
  public unassignedListPage = 0;
  public unassignedListTotalPage = 0;

  constructor(
    @Inject(FIREBASE_REFERENCES.TAIWAN_FIRESTORE) private readonly twFirestore: Firestore,
    @Inject(GET_SITE) private site: string,
    public dialog: MatDialog,
    private liffService: LiffService,
    private firestore: Firestore
  ) { }

  /**
   * 把site config一次性存取下來
   */
  async initSiteConfig(): Promise<void> {
    try {
      const siteDocRef = doc(this.firestore, 'sites', this.site);
      const docSnapshot = await getDoc(siteDocRef);

      this.siteConfig = docSnapshot.data() as SiteConfig;
      this.logo = docSnapshot.get('medOption.liffConfig.logoUrl') as string;
    } catch (error) {
      console.error('Error occurred while getting site config', error);
    }
  }

  async initAssignedHcpList(
    search: { institutionName: string; hcpName: string },
    init: boolean = false
  ): Promise<void> {
    try {
      if (init) {
        this.assignedHcpList = [];
        this.assignedListPage = 0;
        this.assignedListTotalPage = 0;
      }

      const result = await getHcpListFromMedSales(
        this.site,
        this.liffService.profile.userId,
        true,
        search.hcpName,
        search.institutionName,
        1000,
        this.assignedListPage
      );
      this.assignedListPage++;

      this.assignedListTotalPage = result.meta.totalPage;
      this.assignedHcpList = init ? result.rows : [...this.assignedHcpList, ...result.rows];
      return;
    } catch (error) {
      console.error('Error occurred while getting assigned hcp list', error);
    }
  }

  async initUnassignedHcpList(
    search: { institutionName: string; hcpName: string },
    init: boolean = false
  ): Promise<void> {
    try {
      if (init) {
        this.unassignedHcpList = [];
        this.unassignedListPage = 0;
        this.unassignedListTotalPage = 0;
      }

      const result = await getHcpListFromMedSales(
        this.site,
        this.liffService.profile.userId,
        false,
        search.hcpName,
        search.institutionName,
        1000,
        this.unassignedListPage
      );

      this.unassignedListPage++;

      this.unassignedListTotalPage = result.meta.totalPage;
      this.unassignedHcpList = [...this.unassignedHcpList, ...result.rows];
      return;
    } catch (error) {
      console.error('Error occurred while getting unassigned hcp list', error);
    }
  }

  /**
   * 取得科別列表
   */
  async getMedicalDepartmentList(): Promise<Department[]> {
    try {
      const medicalDepartmentList = await getMedicalDepartmentList(this.site);
      return medicalDepartmentList;
    } catch (error) {
      console.error('Error occurred while getting medical department list', error);
      return [];
    }
  }

  async getAddHCPRequiredFields(): Promise<string[]> {
    try {
      const siteDocRef = doc(this.firestore, 'sites', this.site);
      const docSnapshot = await getDoc(siteDocRef);

      if (docSnapshot.exists()) {
        const requiredFields = (docSnapshot.get('medOption.hcp.requiredFields') as string[]) ?? [];
        return requiredFields;
      }

      return [];
    } catch (error) {
      console.error('Error occurred while getting add hcp required fields', error);
      return [];
    }
  }

  /**
   * 取得medSalesKey & viewTerritory
   * @param LINEUserId
   */
  async initMedSalesInfoByLINEUserId(LINEUserId: string): Promise<void> {
    const medSalesRef = collection(this.twFirestore, `/sites/${this.site}/medSales`);
    const salesQuery = query(medSalesRef, where('LINEUserId', '==', LINEUserId));
    const querySnapshot = await getDocs(salesQuery);
    if (querySnapshot.docs.length > 0) {
      this.sales = querySnapshot.docs[0].data() as MedSales;
      this.medSalesKey = querySnapshot.docs[0].id;
      this.medSalesViewTerritory = this.sales.viewTerritory || [];
    }
    return;
  }

  /**
   * 取得特定 sales 資料
   * @param key
   * @returns
   */
  async getSalesByKey(key: string): Promise<MedSales & { pictureUrl: string } | undefined> {
    const docRef = doc(this.twFirestore, `/sites/${this.site}/medSales/${key}`);
    const result = await getDoc(docRef);
    return result.data() as MedSales & { pictureUrl: string };
  }

  /**
   * 取得 site config
   * @returns
   */
  async getSiteConfig() {
    if (this.siteConfig) {
      return this.siteConfig;
    }

    await this.initSiteConfig();
    return this.siteConfig;
  }

  /**
   * 如果進入畫面的是業務，則取得業務資料
   * 如果有帶入 lineUserId，則取得該 lineUserId 的業務資料，並且存取在 sales 中
   * @returns 取得 sales 資料
   */
  async getSales(lineUserId?: string): Promise<MedSales | undefined> {
    if (this.sales && !lineUserId) {
      return this.sales;
    }
    await this.initMedSalesInfoByLINEUserId(lineUserId || this.liffService.profile.userId);
    return this.sales;
  }

  /**
   * 取得業務的 hcpList
   * @returns hcpList
   */
  async getHcpList(): Promise<HCP[]> {
    if (!this.assignedHcpList.length) {
      // 初始化
      await this.initAssignedHcpList({ hcpName: '', institutionName: '' }, true);
    }
    return this.assignedHcpList.filter((hcp) => hcp.LINEUserId);
  }
}
