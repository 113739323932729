<div class="pageWrapper">
  <div class="mainPage">
    <div class="header-box">
      <div class="logo-box">
        <img [src]="logoUrl" class="img-fluid" alt="logo" [width]="40 * 1.5" [height]="29 * 1.5" />
      </div>
      <div class="aiiiIcon">
        <!-- assets/image/sales-register-image-002.svg -->
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2762_402)">
          <mask id="mask0_2762_402" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="80" height="80">
          <path d="M80 0H0V80H80V0Z" fill="white"/>
          </mask>
          <g mask="url(#mask0_2762_402)">
          <path d="M60.5 32.4V71.5C60.5 73.4 58.9 75 57 75H23C21.1 75 19.5 73.4 19.5 71.5V8.5C19.5 6.6 21.1 5 23 5H57C58.9 5 60.5 6.6 60.5 8.5V20.3" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M30.5998 26.0996H29.4998C28.2998 26.0996 27.2998 27.0996 27.2998 28.2996C27.2998 29.4996 28.2998 30.4996 29.4998 30.4996H30.5998C31.7998 30.4996 32.7998 29.4996 32.7998 28.2996C32.7998 27.0996 31.7998 26.0996 30.5998 26.0996Z" fill="var(--icon-secondary-color)"/>
          <path d="M40.5998 26.0996H39.4998C38.2998 26.0996 37.2998 27.0996 37.2998 28.2996C37.2998 29.4996 38.2998 30.4996 39.4998 30.4996H40.5998C41.7998 30.4996 42.7998 29.4996 42.7998 28.2996C42.7998 27.0996 41.7998 26.0996 40.5998 26.0996Z" fill="#333333"/>
          <path d="M49.3997 30.4996H50.4997C51.6997 30.4996 52.6997 29.4996 52.6997 28.2996C52.6997 27.0996 51.6997 26.0996 50.4997 26.0996H49.3997C48.1997 26.0996 47.1997 27.0996 47.1997 28.2996C47.1997 29.4996 48.1997 30.4996 49.3997 30.4996Z" fill="#333333"/>
          <path d="M30.5998 36.8994H29.4998C28.2998 36.8994 27.2998 37.8994 27.2998 39.0994C27.2998 40.2994 28.2998 41.2994 29.4998 41.2994H30.5998C31.7998 41.2994 32.7998 40.2994 32.7998 39.0994C32.7998 37.8994 31.7998 36.8994 30.5998 36.8994Z" fill="#333333"/>
          <path d="M40.5998 36.8994H39.4998C38.2998 36.8994 37.2998 37.8994 37.2998 39.0994C37.2998 40.2994 38.2998 41.2994 39.4998 41.2994H40.5998C41.7998 41.2994 42.7998 40.2994 42.7998 39.0994C42.7998 37.8994 41.7998 36.8994 40.5998 36.8994Z" fill="#333333"/>
          <path d="M50.5998 36.8994H49.4998C48.2998 36.8994 47.2998 37.8994 47.2998 39.0994C47.2998 40.2994 48.2998 41.2994 49.4998 41.2994H50.5998C51.7998 41.2994 52.7998 40.2994 52.7998 39.0994C52.7998 37.8994 51.7998 36.8994 50.5998 36.8994Z" fill="#333333"/>
          <path d="M30.5998 47.7998H29.4998C28.2998 47.7998 27.2998 48.7998 27.2998 49.9998C27.2998 51.1998 28.2998 52.1998 29.4998 52.1998H30.5998C31.7998 52.1998 32.7998 51.1998 32.7998 49.9998C32.7998 48.7998 31.7998 47.7998 30.5998 47.7998Z" fill="#333333"/>
          <path d="M40.5998 47.7998H39.4998C38.2998 47.7998 37.2998 48.7998 37.2998 49.9998C37.2998 51.1998 38.2998 52.1998 39.4998 52.1998H40.5998C41.7998 52.1998 42.7998 51.1998 42.7998 49.9998C42.7998 48.7998 41.7998 47.7998 40.5998 47.7998Z" fill="#333333"/>
          <path d="M50.5998 47.7998H49.4998C48.2998 47.7998 47.2998 48.7998 47.2998 49.9998C47.2998 51.1998 48.2998 52.1998 49.4998 52.1998H50.5998C51.7998 52.1998 52.7998 51.1998 52.7998 49.9998C52.7998 48.7998 51.7998 47.7998 50.5998 47.7998Z" fill="#333333"/>
          <path d="M29.7002 5.2002H50.4002V7.8002C50.4002 10.1002 48.5002 12.0002 46.2002 12.0002H34.0002C31.7002 12.0002 29.8002 10.1002 29.8002 7.8002V5.2002H29.7002Z" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M29.6001 67.2998H50.4001" stroke="#333333" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
          </g>
          </g>
          <defs>
          <clipPath id="clip0_2762_402">
          <rect width="80" height="80" fill="white"/>
          </clipPath>
          </defs>
        </svg>
      </div>
      <div class="title-box">請進行簡訊驗證</div>
    </div>
    <div class="body-box">
      <div class="title-box">
        已寄送驗證碼至您的手機，若您的手機號碼錯誤，請點擊上一步進行修改。
      </div>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>驗證碼</mat-label>
        <input
          type="text"
          matInput
          [(ngModel)]="verificationCode"
          autocomplete="one-time-code"
          inputmode="numeric"
          pattern="[0-9]*"
          maxlength="12"
          placeholder="驗證碼"
        />
      </mat-form-field>

      <button type="button" class="resendBtn" [disabled]="timer > 0" (click)="sendPhoneCode()">
        {{ timer > 0 ? '重寄驗證碼 (' + timer + 's)' : '重寄驗證碼'}}
      </button>

      <div class="info-box">
        若您未收到驗證碼 <br />
        待 30 秒後可再點選按鈕重新獲得驗證碼
      </div>
      <div class="error-message-box mx-auto px-4 py-2 text-center" *ngIf="!!errorMessage">
        {{ errorMessage }}
      </div>

      <button
        mat-flat-button
        [disabled]="(!checkForm() && !checkVerificationCodeIsPhone()) || isProgress"
        (click)="_btnOtpVerify()"
      >
        送出
      </button>
      <button mat-button (click)="_btnPrev()">上一步</button>
    </div>
  </div>
</div>
<div id="sign-in-button"></div>
