import { VerifyPhoneComponent } from './../shared/components/verify-phone/verify-phone.component';
import { filter } from 'rxjs';
import { BoundTerritory, HcpComprehensiveInfoView, TerritoryInfo } from '@aiii/aiii-types/product/med/big-query-schema/hcp/hcp-comprehensive-info-view';
import {
  ChatMessage,
  ChatMessageTemplate,
  ChatRoom,
  ChatUser,
} from '@aiii/aiii-types/product/med/firestore-schema/med-chat';
import { Timestamp } from 'firebase/firestore';
import { Job } from '../med-sales/med-sales-main/typing';

/**
 * OptionCardComponent 畫面需要用到的型別
 */
export interface OptionCardComponentPage {
  type: string;
  templates: ChatMessageTemplate[];
}

/**
 * RecordCardComponent 畫面需要用到的型別
 */
export interface RecordCardComponentPage extends ChatRoom {
  lastMessage: ChatMessage;
  myKey: string;
  otherUser: ChatUser;
  bindingDetail?: HcpComprehensiveInfoView | undefined;
}

/**
 * RecordCardComponent 畫面需要用到的型別
 */
export interface RecordCardComponentPage_Gsk extends ChatRoom {
  lastMessage: ChatMessage;
  myKey: string;
  otherUser: ChatUser;
  bindingDetail?: HcpComprehensiveInfoView_Gsk | undefined;
}

export type Tab = 'HCP清單' | 'HCP對話功能';

export interface Product {
  _key: string;
  name: string;
  territoryGroupKey?: string;
  createdAt?: Date | Timestamp;
  updatedAt?: Date | Timestamp;
}

export interface BoundTerritory_Gsk extends BoundTerritory {
  productName: string;
  productKey: string;
  customerJourney: string;
}

export interface TerritoryInfo_Gsk extends TerritoryInfo {
  territoryId: string;
  territoryName: string;
  // 添加 productName 屬性
  productName: string;
  customerJourney: string;
}

export interface HcpComprehensiveInfoView_Gsk extends HcpComprehensiveInfoView {
  isFollowing: string;
  territoryInfo: TerritoryInfo_Gsk[];
  boundTerritoryList: BoundTerritory_Gsk[];
  verificationStatus: '尚未加入' | '已驗證' | '註銷';
  pictureUrl?: string | null;
}

export interface Job_Gsk extends Job {
  role: 'medSales' | 'hcp';
  createdAt: Date | Timestamp;
  updatedAt: Date | Timestamp;
}

export enum MedSalesJobName {
  Rep = 'REP',
  FLSM = 'FLSM',
  SLSM = 'SLSM',
  MKT = 'MKT',
  MED = 'MED',
}

export enum TopicsCategory {
  Sales = 'Sales',
  MKT = 'MKT',
  Medical = 'Medical',
}
