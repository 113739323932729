<div class="pageWrapper">
  <div class="dialogTitle" *ngIf="title">{{ title }}</div>
  <div class="content">{{ content }}</div>

  <button type="button" mat-flat-button (click)="clickOk()" *ngIf="okText">
    {{ okText }}
  </button>

  <button type="button" mat-flat-button (click)="clickCancel()" *ngIf="cancelText && data.isShowCancelButton">
    {{ cancelText }}
  </button>
</div>
