import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedMaterialModule } from './shared-material.module';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { CoreModule } from './methods/core.module';
import { VerifyPhoneComponent } from './components/verify-phone/verify-phone.component';
import { InviteHcpQrcodeComponent } from './components/invite-hcp-qrcode/invite-hcp-qrcode.component';
import { CommonDialogComponent } from './components/common-dialog/common-dialog.component';
import { SelectMedicalInstitutionComponent } from './components/select-medical-institution/select-medical-institution.component';
import { SafeUrlPipe } from './pipe/SafeUrlPipe.pipe';

@NgModule({
  imports: [CommonModule, SharedMaterialModule, FormsModule, ReactiveFormsModule, CoreModule],
  declarations: [
    CommonDialogComponent,
    VerifyPhoneComponent,
    InviteHcpQrcodeComponent,
    SelectMedicalInstitutionComponent,
  ],
  exports: [
    VerifyPhoneComponent,
    InviteHcpQrcodeComponent,
    ReactiveFormsModule,
    SelectMedicalInstitutionComponent,
  ],
  providers: [UntypedFormBuilder],
})
export class SharedModule {}
