import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { HcpService } from 'src/app/hcp/hcp.service';
import { CONSOLE_SHARED, ConsoleShared } from '../../providers/console-shared.provider';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Department } from '@aiii/aiii-types/product/med/big-query-schema/department/department';
import { FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-select-medical-institution',
  templateUrl: './select-medical-institution.component.html',
  styleUrl: './select-medical-institution.component.scss',
})
export class SelectMedicalInstitutionComponent implements OnChanges {
  @Input() medicalInstitutionIndex: number = 0;
  @Input() jobList: any[] = [];
  @Input() countyList: any[] = [];
  @Input() departmentList: Department[] = [];
  @Input() medicalInstitutionFormGroup!: FormGroup;
  @Input() hcpKey: string = '';

  @Output() removeMedicalInstitutionEventEmitter: EventEmitter<any> = new EventEmitter();
  @ViewChild('medicalInstitutionNameInput', { static: true }) medicalInstitutionNameInput!: ElementRef;
  @ViewChild('departmentInput', { static: true }) departmentInput!: ElementRef;
  selectedCountry: any = '';
  selectedDistrict: any = '';
  selectedMedicalInstitution: any = '';
  autoCompleteMedicalInstitutionList: any[] = [];
  autoCompleteDepartList: any[] = [];

  medicalInstitutionList: any[] = [];
  isQueryAutocomplete: boolean = false;
  composing = false;

  constructor(private hcpService: HcpService, @Inject(CONSOLE_SHARED) private cs: ConsoleShared) {}

  async ngOnInit(): Promise<void> {
    // 要取綁定 this 才能與 function 中的 this 連結
    this.displayDepartmentName = this.displayDepartmentName.bind(this);
    this.displayMedicalInstitutionName = this.displayMedicalInstitutionName.bind(this);

    if (this.medicalInstitutionFormGroup) {
      if (this.medicalInstitutionFormGroup.getRawValue().boundTerritoryKey) {
        this.medicalInstitutionFormGroup.get('jobKey')?.disable();
        this.medicalInstitutionFormGroup.get('departmentKey')?.disable();
        this.medicalInstitutionFormGroup.get('medicalInstitutionKey')?.disable();
      }
      await this.updateMedicalInstitutionList(
        this.medicalInstitutionFormGroup.getRawValue().medicalInstitutionName,
        this.medicalInstitutionFormGroup.getRawValue().county,
        this.medicalInstitutionFormGroup.getRawValue().district
      );

      this.chooseMedicalInstitutionByMedicalInstitutionKey(
        this.medicalInstitutionFormGroup.getRawValue().medicalInstitutionKey
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['departmentList'] && this.departmentList.length > 0) {
      const department = this.departmentList.find(
        (department) => department._key === this.medicalInstitutionFormGroup.getRawValue().departmentKey
      );

      this.filterDepartMentList(department?.name || '');
      this.displayDepartmentName(this.medicalInstitutionFormGroup.getRawValue().departmentKey);
    }
  }

  private latestFirstLetter: string = '';
  private latestCounty: string = '';
  private latestDistrict: string = '';

  async updateMedicalInstitutionList(inputName: string, county: string, district: string) {
    this.isQueryAutocomplete = true;
    if (!(inputName || (county && district))) {
      this.isQueryAutocomplete = false;
      return;
    }

    if (
      this.latestFirstLetter !== inputName.charAt(0) ||
      this.latestCounty !== county ||
      this.latestDistrict !== district
    ) {
      this.latestFirstLetter = inputName.charAt(0);
      this.latestCounty = county || '';
      this.latestDistrict = district || '';
      this.medicalInstitutionList = await this.hcpService.getMedicalInstitutionListByQuery(
        this.cs.site,
        county ? '' : this.latestFirstLetter,
        this.latestCounty,
        this.latestDistrict
      );
    }

    this.isQueryAutocomplete = false;
  }

  chooseMedicalInstitutionByMedicalInstitutionKey(medicalInstitutionKey: string) {
    if (medicalInstitutionKey) {
      this.medicalInstitutionChange(medicalInstitutionKey);
    }
  }

  countryChange(event: any) {
    this.selectedCountry = event;
    this.districtChange('');
  }

  districtChange(event: any) {
    this.selectedDistrict = event;

    if (this.selectedDistrict === '') {
      this.autoCompleteMedicalInstitutionList = [];
    }

    this.medicalInstitutionChange('');
  }

  async filterMedicalInstitutionList(inputName: string, county?: string, district?: string) {
    this.autoCompleteMedicalInstitutionList = this.medicalInstitutionList.filter((medicalInstitution) => {
      if (
        (!this.selectedCountry || medicalInstitution.county === this.selectedCountry.countyName) &&
        (!this.selectedDistrict ||
          medicalInstitution.district.includes(this.selectedDistrict.districtName) ||
          this.selectedDistrict.districtName.includes(medicalInstitution.district)) &&
        ((this.selectedCountry && !inputName) ||
          (inputName &&
            (medicalInstitution.name.includes(inputName) ||
              inputName.includes(medicalInstitution.name) ||
              medicalInstitution.aliasNameList?.some((aliasName: string) => aliasName.includes(inputName)))))
      ) {
        return true;
      }

      return false;
    });
  }

  async filterDepartMentList(inputName: string) {
    this.autoCompleteDepartList = this.departmentList.filter((department) => {
      if (!inputName || department.name.includes(inputName) || inputName.includes(department.name)) {
        return true;
      }

      return false;
    });
  }

  onDepartmentInput(event: any) {
    this.filterDepartMentList(event.target.value || '');
  }

  displayDepartmentName(departmentKey: string): string {
    const department = this.autoCompleteDepartList?.find((dept) => dept._key === departmentKey);
    this.departmentInput.nativeElement.value = department ? department.name : '';
    return department ? department.name : '';
  }

  displayMedicalInstitutionName(medicalInstitutionKey: string): string {
    const medicalInstitution = this.autoCompleteMedicalInstitutionList?.find(
      (medicalInstitution) => medicalInstitution._key === medicalInstitutionKey
    );

    return medicalInstitution ? medicalInstitution.name : '';
  }

  optionSelectedMedicalInstitution(event: MatAutocompleteSelectedEvent) {
    const medicalInstitutionKey = event.option.value;
    this.medicalInstitutionChange(medicalInstitutionKey);
  }

  optionSelectedDepartment(event: MatAutocompleteSelectedEvent) {
    const departmentKey = event.option.value;
    this.medicalInstitutionFormGroup.patchValue({
      departmentKey: departmentKey,
    });
    this.filterDepartMentList(event.option.viewValue);
  }

  async medicalInstitutionChange(medicalInstitutionKey: string) {
    const originalMedicalInstitutionKey = this.selectedMedicalInstitution._key;
    const medicalInstitution = this.medicalInstitutionList.find((m) => m._key === medicalInstitutionKey) || '';
    this.selectedMedicalInstitution = medicalInstitution ? medicalInstitution : '';
    this.medicalInstitutionFormGroup.patchValue({
      medicalInstitutionKey: medicalInstitution._key || '',
      medicalInstitutionName: medicalInstitution.name || '',
      county: medicalInstitution.county,
      district: medicalInstitution.district,
    });
    const medicalInstitutionName = medicalInstitution.name || '';

    const county = this.countyList.find((c) => c.countyName === medicalInstitution.county);
    if (county) {
      this.selectedCountry = county;
      const district = county.districtList.find(
        (d: any) =>
          d.districtName.includes(medicalInstitution.district) || medicalInstitution.district.includes(d.districtName)
      );
      if (district) {
        this.selectedDistrict = district;
      }
    }
    await this.updateMedicalInstitutionList(
      medicalInstitutionName,
      this.selectedCountry?.countyName,
      this.selectedDistrict?.districtName
    );

    await this.filterMedicalInstitutionList(
      medicalInstitutionName,
      this.selectedCountry?.countyName,
      this.selectedDistrict?.districtName
    );

    this.medicalInstitutionNameInput.nativeElement.value = medicalInstitutionName;

    if (originalMedicalInstitutionKey === medicalInstitution._key) {
      return;
    }
  }

  onInputBlur() {
    setTimeout(() => {
      const inputMedicalInstitutionName = this.medicalInstitutionNameInput.nativeElement.value;

      if (
        this.selectedMedicalInstitution === '' ||
        inputMedicalInstitutionName !== this.selectedMedicalInstitution.name
      ) {
        const medicalInstitution = this.autoCompleteMedicalInstitutionList[0] || '';

        this.medicalInstitutionChange(medicalInstitution._key);
      }
    }, 300);
  }

  onDepartmentInputBlur() {
    setTimeout(() => {
      // 取得 department 是否為 required
      const isDepartmentRequired = this.hasRequiredValidator('departmentKey');
      // 如果是 required 且有輸入任何字的話，就要選第一個
      if (isDepartmentRequired || this.departmentInput.nativeElement.value) {
        const department = this.autoCompleteDepartList[0] || '';
        this.medicalInstitutionFormGroup.patchValue({
          departmentKey: department._key || '',
        });
        this.filterDepartMentList(department.name || '');
      }
    }, 300);
  }

  async inputChange(inputValue: string) {
    await this.updateMedicalInstitutionList(
      inputValue,
      this.selectedCountry?.countyName,
      this.selectedDistrict?.districtName
    );
    await this.filterMedicalInstitutionList(inputValue);
  }

  removeMedicalInstitution() {
    this.removeMedicalInstitutionEventEmitter.emit(this.medicalInstitutionIndex);
  }
  onInput(event: any) {
    if (!this.composing) {
      this.inputChange(event.target.value);
    }
  }

  compositionStart() {
    this.composing = true;
  }

  // 中文選
  compositionEnd(event: any) {
    this.composing = false;
    this.inputChange(event.target.value);
  }

  hasRequiredValidator(controlName: string): boolean {
    const control = this.medicalInstitutionFormGroup.get(controlName);
    return control?.hasValidator(Validators.required) || false;
  }
}
