import { Inject, Injectable } from '@angular/core';
import { HCP, HCPGsk, MedicalInstitution } from 'src/app/med-sales/med-sales-main/typing';
import { getHcpListFromMedSalesGsk, HcpListParams } from 'src/app/shared/methods/api/get-hcp-list-from-med-sales-gsk';
import { Job_Gsk, MedSalesJobName, Product, Tab } from '../typings';
import { FIREBASE_REFERENCES } from 'src/app/shared/methods/firebase.module';
import { collection, doc, DocumentSnapshot, Firestore, getDoc, getDocs, query, where } from '@angular/fire/firestore';
import { Territory } from '@aiii/aiii-types/product/med/big-query-schema/territory/territory';
import { MedSales } from '@aiii/aiii-types/product/med/firestore-schema/med-sales/med-sales';

export interface ProductForDataSource {
  name: string;
  segment: string;
  customerJourney: string;
}
export interface HcpDataSource {
  name: string;
  hcpHospitals: HcpHospital[];
  verificationStatus?: '尚未加入' | '已驗證' | '註銷';
  isFollowing?: boolean;
  _key: string;
  LINEUserId: string;
}

export interface HcpHospital {
  medicalInstitutionName: string;
  productNumber: number;
  specialties: Specialty[];
}

export interface Specialty {
  departmentName: string;
  productNumber: number;
  products: ProductForDataSource[];
}

export interface MedicalInstitutionData {
  _key: string;
  medicalInstitutionName: string;
}
@Injectable({
  providedIn: 'root',
})
export class MedSalesListService {
  /**
   * 業務的 site
   */
  private site = 'gskline';

  /**
   * 啟用的 Tab(menu)
   */
  public activeTab!: Tab;

  /**
   * 此業務對應的 hcp 清單
   */
  public hcpList: HCPGsk[] = [];

  /**
   * 此業務 hcp 清單對應的醫院清單
   */
  public medicalInstitutions: MedicalInstitutionData[] = [];

  /**
   * 此業務 territoryGroup 清單對應的產品清單
   */
  public products: Product[] = [];

  /**
   * 此業務的 territory
   */
  public territory: string[] = [];

  /**
   * 業務資料
   */
  public medSales: MedSales | undefined;

  /**
   * 業務的 JobData
   */
  public medSalesJobName: string = '';

  /**
   * 業務的權限
   */
  public medSalesAuthOptions: {
    tabs: Tab[],
    canActiveMenuMap: {
      [key: string]: boolean
    }
  } = {
      tabs: [],
      canActiveMenuMap: {
        'list': true,
        'chat': false,
      }
    };

  /**
   * hcpMap
   */
  public hcpMap: Map<string, HcpDataSource> = new Map();


  constructor(@Inject(FIREBASE_REFERENCES.TAIWAN_FIRESTORE) private readonly twFirestore: Firestore) { }

  /**
   * 已指派的 HCP List
   */
  async initHcpList({
    site,
    medSalesKey,
    nameSearch,
    institutionSearch,
    productSearch,
    classSearch,
  }: HcpListParams): Promise<any> {
    return await getHcpListFromMedSalesGsk({
      site,
      medSalesKey,
      nameSearch,
      institutionSearch,
      productSearch,
      classSearch,
    });
  }

  /**
   * 測試用，取得業務資料，實際再依照需求修改
   * 如果有帶入 lineUserId，則取得該 lineUserId 的業務資料，並且存取在 sales 中
   * @returns 取得 sales 資料
   */
  async getSalesForTest(email: string): Promise<MedSales | undefined> {
    if (!email) {
      return undefined;
    }
    // 透過比對 email 取得業務資料
    const medSalesRef = collection(this.twFirestore, `/sites/${this.site}/medSales`);
    const medSalesQuery = query(medSalesRef, where('email', '==', email));
    const querySnapshot = await getDocs(medSalesQuery);
    if (querySnapshot.docs.length > 0) {
      // 取得業務資料
      this.medSales = querySnapshot.docs[0].data() as MedSales;
      // 取得業務的 JobName
      this.medSalesJobName = await this.getMedSalesJobName(querySnapshot.docs[0].data()['jobKey']);
      // 取得業務的選單
      if (this.medSales) {
        this.getMedSalesMenu(this.medSalesJobName);
      }
      return this.medSales;
    }
    return undefined;
  }

  /**
   * 取得 medSalesJobName
   */
  async getMedSalesJobName(jobKey: string): Promise<Job_Gsk['name']> {
    const jobRef = doc(this.twFirestore, `/sites/${this.site}/job/${jobKey}`);
    const documentSnapshot: DocumentSnapshot = await getDoc(jobRef);
    if (documentSnapshot.exists()) {
      const data = documentSnapshot.data() as Job_Gsk;
      return data.name ? data.name : '';
    }
    return '';
  }

  /**
   * 取得此業務的選單
   */
  getMedSalesMenu(medSalesJobName: string) {
    switch (medSalesJobName) {
      case MedSalesJobName.Rep:
        this.medSalesAuthOptions.tabs = ['HCP清單', 'HCP對話功能'];
        this.medSalesAuthOptions.canActiveMenuMap['list'] = true;
        this.medSalesAuthOptions.canActiveMenuMap['chat'] = true;
        break
      case MedSalesJobName.MED:
        this.medSalesAuthOptions.tabs = ['HCP清單', 'HCP對話功能'];
        this.medSalesAuthOptions.canActiveMenuMap['list'] = true;
        this.medSalesAuthOptions.canActiveMenuMap['chat'] = true;
        break
      default:
        this.medSalesAuthOptions.tabs = ['HCP清單'];
        this.medSalesAuthOptions.canActiveMenuMap['list'] = true;
    }
  }

  /**
   * 取得業務的 hcpList
   * @returns hcpList
   */
  async getHcpList(medSalesKey: string): Promise<HCPGsk[]> {
    if (!this.hcpList.length) {
      // 初始化
      const result = await this.initHcpList({
        site: 'gskline',
        medSalesKey: medSalesKey,
        institutionSearch: '',
        nameSearch: '',
        productSearch: '',
        classSearch: '',
      });
      return result.rows;
    }
    return this.hcpList;
  }

  /**
   * 取得業務的醫院清單
   * @param {string} territoryGroupKey
   * @returns {Promise<Product[]>}
   * @memberof MedSalesListService
   */
  async getProductList(territoryGroupKey: string): Promise<Product[]> {
    const productsRef = collection(this.twFirestore, `/sites/${this.site}/products`);
    const productsQuery = query(productsRef, where('territoryGroupKey', '==', territoryGroupKey));
    const querySnapshot = await getDocs(productsQuery);
    if (querySnapshot.docs.length > 0) {
      return querySnapshot.docs.map((doc) => doc.data() as Product);
    }
    return [];
  }

  /**
   * 將 HCP 資料轉換成表格資料以及院所資料
   * @param hcps
   * @returns {dataSources: HcpDataSource[], medicalInstitutions: MedicalInstitutionData[], hcpMap: Map<string, HcpDataSource>}
   */
  transformHcpData(hcps: HCPGsk[]): {
    dataSources: HcpDataSource[];
    medicalInstitutions: MedicalInstitutionData[];
    hcpMap: Map<string, HcpDataSource>;
  } {
    const dataSources: HcpDataSource[] = [];
    const medicalInstitutionMap = new Map<string, MedicalInstitutionData>();
    const hcpMap: Map<string, HcpDataSource> = new Map();

    for (let hcp of hcps) {
      // 用於醫師列表(表格)的醫院/科別/產品比對
      type HcpProductMap = Map<string, ProductForDataSource>;
      type HcpSpecialtyMap = Map<string, { departmentName: string; hcpProductMap: HcpProductMap }>;
      type HcpHospitalMap = Map<
        string,
        { medicalInstitutionName: string; productNumber: number; hcpSpecialtyMap: HcpSpecialtyMap }
      >;
      const hcpHospitalMap: HcpHospitalMap = new Map();

      for (let boundTerritory of hcp.boundTerritoryList) {
        // 如果醫院名稱或醫院 key 為空，則跳過
        if (!boundTerritory.medicalInstitutionKey || !boundTerritory.medicalInstitutionName) {
          continue;
        }
        // 如果科別名稱或科別 key 為空，則跳過
        if (!boundTerritory.departmentKey || !boundTerritory.departmentName) {
          continue;
        }

        //  醫師列表全部醫院名稱(用於 filter)
        if (!medicalInstitutionMap.has(boundTerritory.medicalInstitutionKey)) {
          medicalInstitutionMap.set(boundTerritory?.medicalInstitutionKey, {
            medicalInstitutionName: boundTerritory?.medicalInstitutionName,
            _key: boundTerritory?.medicalInstitutionKey,
          });
        }

        // 單一醫師的資料(以下長篇比對用於 醫師列表 table)
        // 如果沒有醫院資料，新增一筆資料
        if (!hcpHospitalMap.has(boundTerritory.medicalInstitutionKey)) {
          const hcpProductMap: HcpProductMap = new Map();
          const hcpSpecialtyMap: HcpSpecialtyMap = new Map();
          hcpHospitalMap.set(boundTerritory.medicalInstitutionKey, {
            medicalInstitutionName: boundTerritory.medicalInstitutionName,
            productNumber: 1,
            hcpSpecialtyMap: hcpSpecialtyMap.set(boundTerritory?.departmentKey, {
              departmentName: boundTerritory?.departmentName,
              hcpProductMap: hcpProductMap.set(boundTerritory.productKey, {
                name: boundTerritory?.productName ?? '',
                segment: boundTerritory?.targetClass ?? '',
                customerJourney: boundTerritory?.customerJourney ?? '',
              }),
            }),
          });
          continue;
        }

        // 如果有醫院資料，但沒有科別資料，新增一筆科別資料
        if (
          !hcpHospitalMap.get(boundTerritory.medicalInstitutionKey)?.hcpSpecialtyMap.has(boundTerritory.departmentKey)
        ) {
          const hcpProductMap: HcpProductMap = new Map();
          hcpHospitalMap.get(boundTerritory.medicalInstitutionKey)?.hcpSpecialtyMap.set(boundTerritory.departmentKey, {
            departmentName: boundTerritory.departmentName,
            hcpProductMap: hcpProductMap.set(boundTerritory.productKey, {
              name: boundTerritory?.productName ?? '',
              segment: boundTerritory?.targetClass ?? '',
              customerJourney: boundTerritory.customerJourney ?? '',
            }),
          });
          const hospital = hcpHospitalMap.get(boundTerritory.medicalInstitutionKey);
          if (hospital) {
            hospital.productNumber++;
          }
          continue;
        }
        // 如果有醫院資料、科別資料，但沒有產品資料，新增一筆產品資料
        if (
          !hcpHospitalMap
            .get(boundTerritory.medicalInstitutionKey)
            ?.hcpSpecialtyMap.get(boundTerritory.departmentKey)
            ?.hcpProductMap.has(boundTerritory.productKey)
        ) {
          hcpHospitalMap
            .get(boundTerritory.medicalInstitutionKey)
            ?.hcpSpecialtyMap.get(boundTerritory.departmentKey)
            ?.hcpProductMap.set(boundTerritory.productKey, {
              name: boundTerritory.productName ?? '',
              segment: boundTerritory.targetClass ?? '',
              customerJourney: boundTerritory.customerJourney ?? '',
            });
          const hospital = hcpHospitalMap.get(boundTerritory.medicalInstitutionKey);
          if (hospital) {
            hospital.productNumber++;
          }
          continue;
        }
      }

      if (hcpHospitalMap.size === 0) {
        continue;
      }

      // 比對完畢後，把 hcpHospitalMap 轉換成 hcpHospitals，並且存入 dataSources
      const getHcpHospitals = (hcpHospitalMap: HcpHospitalMap): HcpHospital[] => {
        return Array.from(hcpHospitalMap.values(), (hcpHospital) => {
          return {
            medicalInstitutionName: hcpHospital.medicalInstitutionName,
            productNumber: hcpHospital.productNumber,
            specialties: Array.from(hcpHospital.hcpSpecialtyMap.values(), (hcpSpecialty) => {
              return {
                departmentName: hcpSpecialty.departmentName,
                productNumber: hcpSpecialty.hcpProductMap.size,
                products: Array.from(hcpSpecialty.hcpProductMap.values()),
              } as unknown as HcpHospital['specialties'];
            }),
          } as unknown as HcpHospital;
        });
      };

      const hcpData = {
        _key: hcp._key,
        LINEUserId: hcp.LINEUserId,
        name: hcp.name,
        hcpHospitals: getHcpHospitals(hcpHospitalMap),
        verificationStatus: hcp.verificationStatus,
        isFollowing: hcp?.isFollowing === 'true' || false,
      };
      dataSources.push(hcpData as unknown as HcpDataSource);
      hcpMap.set(hcp._key, hcpData);
    }
    return {
      dataSources: dataSources,
      medicalInstitutions: Array.from(medicalInstitutionMap.values()),
      hcpMap: hcpMap,
    };
  }
}
