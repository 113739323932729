import { Component, Inject, OnInit } from '@angular/core';
import { generateQrCodeUrl } from '@aiii/aiii-shared-front-end/tools/generate-qr-code-url';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MedSalesMainService } from 'src/app/med-sales/med-sales-main/med-sales-main.service';
import { InviteHcpQrcodeData } from './typing';

@Component({
  selector: 'app-invite-hcp-qrcode',
  templateUrl: './invite-hcp-qrcode.component.html',
  styleUrls: ['./invite-hcp-qrcode.component.scss'],
})
export class InviteHcpQrcodeComponent implements OnInit {
  public inviteHcpQrCodeUrl!: string;
  public hcpRegisterUrl!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InviteHcpQrcodeData,
    public medSalesMainService: MedSalesMainService,
  ) { }

  async ngOnInit(): Promise<void> {
    // 產生 QRCode
    this.hcpRegisterUrl = `https://liff.line.me/${this.data.medLiffId}/hcp/hcp-register?hcpKey=${this.data.hcpKey}&medSalesKey=${this.data.medSalesKey}`;
    this.inviteHcpQrCodeUrl = generateQrCodeUrl(this.hcpRegisterUrl, 200);
  }

  downloadImage(url: string): void {
    const nUrl = new URL(url);
    nUrl.searchParams.set('openExternalBrowser', '1');
    window.open(nUrl.href, '_blank');
  }

  downloadQrcode() {
    // 將 inviteHcpQrCodeUrl 的圖片下載到裝置端
    const nUrl = new URL(this.inviteHcpQrCodeUrl);
    nUrl.searchParams.set('openExternalBrowser', '1');
    window.open(nUrl.href, '_blank');
  }
}
