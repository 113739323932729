import { Component, OnInit } from '@angular/core';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { filter, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

import { ThemeService } from './shared/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'aiii-med';
  private routerSubscription!: Subscription;

  constructor(
    private router: Router,
    private themeService: ThemeService,
    private firestore: Firestore,
  ) {}

  ngOnInit(): void {
    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.initializeTheme();
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  private async initializeTheme(): Promise<void> {
    const url = new URL(window.location.href);
    const site = url.searchParams.get('site');

    if (site) {
      const siteDocRef = doc(this.firestore, 'sites', site);
      const docSnapshot = await getDoc(siteDocRef);

      const theme = docSnapshot.get('medOption.liffConfig.theme');
      if (theme) {
        this.themeService.setTheme(theme);
      }
    }
  }
}
